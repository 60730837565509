import React from "react";
import cx from "classnames";
import dayjs from "dayjs";
import styles from "./Banner.module.scss";

export interface BannerProps {
  formattedPhone: string;
}

declare let bannerSettings: string;

export const Banner = () => {
  const bsetting = bannerSettings ? bannerSettings.split("~") : null;
  if (bsetting === null) return null;

  const showBanner =
    bsetting.length > 0 &&
    bsetting[0] === "publish" &&
    dayjs(bsetting[2]).add(1, "day").isAfter(dayjs());
  if (showBanner) {
    return (
      <div className={cx(styles.banner, styles[bsetting[1]])}>
        🕐&nbsp;
        <a target="_blank" href={bsetting[4]} rel="noreferrer">
          <u>{bsetting[3]}</u>
        </a>
      </div>
    );
  } else {
    return null;
  }
};
