import React, { useEffect } from "react";
import cx from "classnames";
import styles from "./UpcomingAppointment.module.scss";
import { OfficeInfo } from "./OfficeInfo";
import { cancelModalAria, CancelModalContent } from "./CancelModalContent";
import {
  rescheduleModalAria,
  RescheduleModalContent,
} from "./RescheduleModalContent";
import { UpcomingAppointmentSkeleton } from "./UpcomingAppointmentSkeleton";
import { useCurrentAppointment } from "./hooks/useCurrentAppointment";
import { useSnackbar } from "./hooks/useSnackbar";
import { useLastAppointment } from "features/checkIn/hooks/useLastAppointment";
import { ButtonLinkInternal, ButtonLinkExternal } from "components/Button";
import { TextButton } from "components/TextButton";
import { parseStartTimeWithZone, timeUntil } from "features/appointmentTime";
import { Card } from "components/Card";
import { doctorFullName } from "utils/doctorFullName";
import { DoctorAvatar } from "components/DoctorAvatar";
import { Modal } from "components/Modal";
import { CalendarLinkModal } from "components/CalendarLinkModal";
import { checkInHome } from "features/routing/paths";
import {
  checkinCompleted,
  insuranceSkipped,
  insuranceFinished,
  aboutYouSubmitted,
} from "features/checkIn/helpers";
import { CurrentAppointment } from "types/currentAppointment";
import { useCurrentPatientInsurance } from "features/checkIn/hooks/useCurrentPatientInsurance";
import { RetiringLabel } from "features/scheduling/components/RetiringLabel";
declare let highlightUpcomingResource: string;
export enum CancellationStatus {
  No,
  ModalOpen,
  Canceled,
}

export const CheckinAction: React.FC<{
  currentAppointment: CurrentAppointment;
}> = ({ currentAppointment }) => {
  const { checkin } = currentAppointment;
  if (checkinCompleted(checkin)) {
    return null;
  }

  return (
    <div className={styles.startCheckIn}>
      <strong>Save time in the office.</strong>
      <div className={styles.checkInButtonContainer}>
        <ButtonLinkInternal
          className={cx({
            [styles.incompleteCheckinButton]: aboutYouSubmitted(checkin),
          })}
          text={
            aboutYouSubmitted(checkin) ? "Finish check-in" : "Check-in Online"
          }
          size="large"
          to={checkInHome()}
        />
      </div>
      <div>
        Your appointment is <strong>{timeUntil(currentAppointment)}</strong>{" "}
        away.
      </div>
    </div>
  );
};

export const UpcomingAppointment: React.FC = () => {
  const [mightBeRescheduling, setMightBeRescheduling] = React.useState(false);
  const [isCalendarOpen, setCalendarOpen] = React.useState(false);
  const [cancellationStatus, setCancellationStatus] = React.useState(
    CancellationStatus.No
  );
  const { currentAppointment, fetchCurrentAppointment } =
    useCurrentAppointment();
  const { lastAppointment, fetchLastAppointment } = useLastAppointment();
  const { currentPatientInsurance, fetchCurrentPatientInsurance } =
    useCurrentPatientInsurance();
  const { displayMessage } = useSnackbar();
  useEffect(() => {
    if (!currentAppointment) {
      return;
    }

    if (cancellationStatus === CancellationStatus.Canceled) {
      displayMessage({
        icon: "checkmark",
        text: "Your appointment was canceled.",
      });
      setCancellationStatus(CancellationStatus.No); // Return to initial state.
      fetchCurrentAppointment();
    }
  }, [
    cancellationStatus,
    currentAppointment,
    displayMessage,
    fetchCurrentAppointment,
  ]);

  useEffect(() => {
    fetchLastAppointment();
  }, [fetchLastAppointment]);

  useEffect(() => {
    fetchCurrentPatientInsurance();
  }, [fetchCurrentPatientInsurance]);

  if (currentAppointment === undefined) {
    return <UpcomingAppointmentSkeleton />;
  }

  if (currentAppointment === null) {
    return (
      <>
        <div className={styles.cardContainer}>
          <Card className={styles.card}>
            <h1 className={styles.header}>Upcoming Appointments</h1>
            <div className={styles.instruction}>No upcoming appointments.</div>

            <div className={styles.buttonContainer}>
              <ButtonLinkExternal
                href="https://locations.myeyedr.com/search?q="
                text="Book Appointment"
                size="large"
              />
            </div>
          </Card>
          {lastAppointment && <br></br>}
          {lastAppointment && (
            <Card className={styles.card}>
              <h1 className={styles.header}>Last Appointment</h1>
              <div className={styles.instruction}>
                <div className={styles.date}>
                  <strong>
                    {parseStartTimeWithZone(lastAppointment).format(
                      "MMM D, YYYY"
                    )}
                  </strong>
                </div>
                <div
                // className={cx(
                //   styles.officeContainer,
                //   styles.singleLineAddress
                // )}
                >
                  <OfficeInfo
                    officeId={lastAppointment.officeId}
                    singleLine={true}
                  />
                </div>
              </div>
            </Card>
          )}
        </div>
      </>
    );
  }

  const calendarLinks = currentAppointment?.calendarLinks;
  const dateTime = parseStartTimeWithZone(currentAppointment);
  const checkin = currentAppointment.checkin;
  highlightUpcomingResource =
    typeof highlightUpcomingResource !== "undefined"
      ? highlightUpcomingResource
      : "";
  //const discountVEHDoctor = doctorDiscountNameVEH();
  const doctorName = doctorFullName(currentAppointment.doctor);
  return (
    <>
      <div className={styles.cardContainer}>
        <Card className={styles.card}>
          <h1 className={styles.header}>Upcoming Appointments</h1>
          {currentAppointment.doctor.id !== highlightUpcomingResource && (
            <>
              <div className={styles.avatarContainer}>
                <DoctorAvatar size="small" doctor={currentAppointment.doctor} />
              </div>
              {currentAppointment.doctor.retiringDate && (
                <>
                  <RetiringLabel
                    doctor={currentAppointment.doctor}
                    calledFrom="upcomeAppointment"
                  />
                  <br />
                </>
              )}
              <div className={styles.dateContainer}>
                <div className={styles.date}>
                  {dateTime.format("h:mm a, ddd, MMM D, YYYY")}
                </div>
                <div className={styles.doctor}>with {doctorName}</div>
                <TextButton
                  onClick={(ev) => {
                    setCalendarOpen(true);
                    ev.stopPropagation();
                  }}
                  className={styles.addToCalendarButton}
                >
                  Add To Calendar
                </TextButton>
              </div>
            </>
          )}
          {currentAppointment.doctor.id === highlightUpcomingResource && (
            <div className={styles.dummyDiv}></div>
          )}
          <div className={styles.officeContainer}>
            <OfficeInfo
              officeId={currentAppointment.officeId}
              showOfficeNumber={
                currentAppointment.doctor.id === highlightUpcomingResource
                  ? true
                  : false
              }
            />
          </div>
          {currentAppointment.doctor.id === highlightUpcomingResource && (
            <div className={styles.highlight}>
              <address>
                Your appointment has not been confirmed and may need to be
                rescheduled. Please contact the office as soon as possible.
              </address>
            </div>
          )}
          {insuranceFinished(checkin) && (
            <div className={styles.checkinStatusContainer}>
              Insurance submitted for this appointment.
            </div>
          )}

          {insuranceSkipped(checkin) && (
            <div className={styles.checkinStatusContainer}>
              Not using insurance for this appointment
            </div>
          )}

          <CheckinAction currentAppointment={currentAppointment} />
        </Card>

        <div className={styles.ctas}>
          {currentAppointment.isReschedulable && (
            <>
              <TextButton
                onClick={() => {
                  setMightBeRescheduling(true);
                }}
                className={styles.textButton}
              >
                Reschedule
              </TextButton>
              |
            </>
          )}
          <TextButton
            onClick={() => {
              setCancellationStatus(CancellationStatus.ModalOpen);
            }}
            className={styles.textButton}
          >
            Cancel
          </TextButton>
        </div>
        {lastAppointment && <br></br>}
        {lastAppointment && (
          <Card className={styles.card}>
            <h1 className={styles.header}>Last Appointment</h1>
            <div className={styles.instruction}>
              <div className={styles.date}>
                <strong>
                  {parseStartTimeWithZone(lastAppointment).format(
                    "MMM D, YYYY"
                  )}
                </strong>
              </div>
              <div
              // className={cx(styles.officeContainer, styles.singleLineAddress)}
              >
                <OfficeInfo
                  officeId={lastAppointment.officeId}
                  singleLine={true}
                />
              </div>
            </div>
          </Card>
        )}
        {(currentAppointment.checkin.insurance === "needs_verification" ||
          currentAppointment.checkin.insurance === "finished") && (
          <div>
            <br />
            <Card className={styles.card}>
              <h1 className={styles.insuranceHeader}>Insurance Coverage</h1>
              <div className={styles.insuranceContainer}>
                {currentPatientInsurance &&
                  currentPatientInsurance.length > 0 && (
                    <>
                      <div className={styles.insuranceHeading}>
                        {currentPatientInsurance[0].insuranceCarrier}
                      </div>
                      <div>
                        Subscriber:{" "}
                        {currentPatientInsurance[0].subscriberFirstName}{" "}
                        {currentPatientInsurance[0].subscriberLastName} (
                        {currentPatientInsurance[0].subscriberRelationship})
                      </div>
                      {currentPatientInsurance[1] && (
                        <>
                          <div className={styles.insuranceHeading}>
                            {currentPatientInsurance[1].insuranceCarrier}
                          </div>
                          <div>
                            Subscriber:{" "}
                            {currentPatientInsurance[1].subscriberFirstName}{" "}
                            {currentPatientInsurance[1].subscriberLastName} (
                            {currentPatientInsurance[1].subscriberRelationship})
                          </div>
                        </>
                      )}
                    </>
                  )}
                {currentPatientInsurance &&
                  currentPatientInsurance.length === 0 && (
                    <>
                      <div className={styles.insuranceHeading}>
                        Insurance submitted
                      </div>
                      <div className={styles.insuranceNotFoundSubtext}>
                        Our office may follow-up with you to ensure your status
                        regarding Insurance.
                      </div>
                    </>
                  )}
              </div>
            </Card>
          </div>
        )}
      </div>

      <Modal
        aria={cancelModalAria}
        isOpen={cancellationStatus === CancellationStatus.ModalOpen}
        onRequestClose={() => setCancellationStatus(CancellationStatus.No)}
      >
        <CancelModalContent setCancellationStatus={setCancellationStatus} />
      </Modal>
      <Modal
        isOpen={mightBeRescheduling}
        onRequestClose={() => setMightBeRescheduling(false)}
        aria={rescheduleModalAria}
      >
        <RescheduleModalContent
          officeId={currentAppointment?.officeId}
          setMightBeReschedulingFn={setMightBeRescheduling}
        />
      </Modal>
      <CalendarLinkModal
        onRequestClose={() => setCalendarOpen(false)}
        isModalOpen={isCalendarOpen}
        links={calendarLinks}
      />
    </>
  );
};
