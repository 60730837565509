import React, { useEffect, useState } from "react";
import cx from "classnames";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as paths from "features/routing/paths";
import styles from "./AppointmentSuccessNew.module.scss";
import {
  reinitialize,
  selectCalendarLinks,
  selectCreatedAppointment,
  selectPatient,
} from "features/scheduling/appointmentCreationSlice";
import { cloneDeep, isEmpty } from "lodash";
import { selectInsuranceData } from "features/insuranceDataSlice";
import { CalendarLinkModal } from "components/CalendarLinkModal";
import { parseStartTimeWithZone } from "features/appointmentTime";
import { Doctor } from "types/doctor";
import { DoctorInfoCard } from "components/DoctorInfoCard";
import { OfficeInfoCard } from "components/OfficeInfoCard";
import { Layout } from "features/layout";
import { Button, ButtonRole } from "components/Button";
import { usePageViewTracking } from "hooks/usePageViewTracking";
import { Office } from "types/office";
import { SelectedAppointment } from "types/selectedAppointment";
import { Navbar } from "components/Navbar";
import { CheckInCardNew } from "features/scheduling/components/CheckInCardNew";
import confirmationIcon from "images/confirmation-icon.svg";
import { createAboutYouNewRequest } from "api/createAboutYouRequest";
import { createInsuranceCoverageNewRequest } from "api/createInsuranceCoverageRequest";
import { skipInsuranceCoverageNewRequest } from "api/skipInsuranceCoverageRequest";
import { WithOffice } from "components/WithOffice/WithOffice";
import { NeedsSelectedAppointment } from "components/NeedsSelectedAppointment";
import { ConfirmAboutYou1 } from "features/checkIn/ConfirmAboutYou1";
import { AboutYou1FormData, InsuranceFormData, RelationToPatient } from "features/checkIn/types";
import { ConfirmInsurance } from "features/checkIn/ConfirmInsurance";

interface AppointmentSuccessProps {
  office: Office;
  doctor: Doctor;
  selectedAppointment: SelectedAppointment;
}

export const UnconnectedAppointmentSuccessNew: React.FC<
  AppointmentSuccessProps
> = ({ office, doctor, selectedAppointment }) => {
  const history = useHistory();
  const parsedTime = parseStartTimeWithZone(selectedAppointment);
  const formattedDate = parsedTime.format("ddd. MMM D, YYYY");
  const formattedTime = parsedTime.format("h:mm A");
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isSubmiting, setSubmiting] = useState(false);
  const [addressDATA, setAddressDATA] = useState<AboutYou1FormData>();
  const [insuranceDATA, setInsuranceDATA] = useState<InsuranceFormData[]>();
  const [insChecked, setInsChecked] = useState(false);
  const createdAppointment = useSelector(selectCreatedAppointment);
  const patient = useSelector(selectPatient);
  const insuranceFormDataArr = useSelector(selectInsuranceData);
  usePageViewTracking("step-8-appointment-created", {
    doctorId: doctor.id,
    patientId: createdAppointment?.patientId,
    patientPhoneNumber: patient?.phoneNumber,
    patientSmsOptIn: patient?.smsOptIn,
    appointmentId: createdAppointment?.id,
  });
  const calendarLinks = useSelector(selectCalendarLinks);

  useEffect(() => {
    return () => {
      dispatch(reinitialize());
    };
  }, [dispatch]);

  const checkFormInsurance = (insurance: InsuranceFormData) => {
    if (
      insurance.insuranceCarrier &&
      insurance.subscriberFirstName &&
      insurance.subscriberLastName &&
      insurance.subscriberId &&
      insurance.subscriberDateOfBirth &&
      insurance.isPrimaryPolicyHolder &&
      insurance.subscriberSsn &&
      insurance.relationToPatient
    ) {
      if (insurance.insuranceCarrier === "Other Insurance") {
        return insurance.otherInsuranceName ? true : false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
  
  const onSubmitNew = async () => {
    setSubmiting(true);   
    let isFormValid = false; 
    let dummyArr = [];
    if(!isEmpty(insuranceFormDataArr[0]) && checkFormInsurance(insuranceFormDataArr[0])){
      dummyArr.push(insuranceFormDataArr[0]);
    }
    if(!isEmpty(insuranceFormDataArr[1]) && checkFormInsurance(insuranceFormDataArr[1])){
      dummyArr.push(insuranceFormDataArr[1]);
    }
    for (const insurance of insuranceFormDataArr) {
      if (!isEmpty(insurance)) {
        isFormValid = checkFormInsurance(insurance) ? true : false;
        if (!isFormValid) break;
      }
    }
    if (
      addressDATA &&
      addressDATA?.address1 &&
      addressDATA?.city &&
      addressDATA?.state &&
      addressDATA?.zipCode &&
      (isFormValid || insChecked)
    ) {
      await createAboutYouNewRequest({
        ...addressDATA,
      });
      if(!insChecked) {
        await createInsuranceCoverageNewRequest(dummyArr);
        setSubmiting(false);
        history.push(paths.checkInHome());
      } else {
        if (insChecked) {
          await skipInsuranceCoverageNewRequest();
          setSubmiting(false);
          history.push(paths.checkInHome());
        }
        setSubmiting(false);
      }
      } else {
        setTimeout(() => {
          setSubmiting(false);
        }, 2000);
      }    
  };

  const getAddressValues = (addressData: any) => {
    setAddressDATA(addressData);
  }

  const getInsuranceValues = async (insuranceDATA: any) => {
    /*nlet tempDATA = cloneDeep(insuranceDATA);
    if (isEmpty(tempDATA[1])) {
      delete tempDATA[1];
    }*/
    //await createInsuranceCoverageNewRequest(tempDATA);
    setSubmiting(false);
    //history.push(paths.checkInHome());
  }

  const handleChangeIns = () => {
    setInsChecked(!insChecked);
  };

  const isFormValidIns = (data:any) => {
    //{formValid:formValid,getInsValues:getValues()}
    if(!data.formValid){
      setSubmiting(false);
    } else {
      setSubmiting(true);
    }  
  }

  return (
    <Layout navbar={<Navbar office={office} />}>
      <div data-testid="appElement" className={styles.container}>
        <div className={styles.twoColumns}>
          <div className={styles.column}>
            <p className={styles.youreScheduled}>You’re Scheduled!</p>
            <h2 className={styles.subheader}>
              {formattedDate} at {formattedTime}
            </h2>
            <div>
              <OfficeInfoCard office={office} />
              <DoctorInfoCard doctor={doctor} office={office} />
            </div>
            <Button
              className={styles.calendarButton}
              text="Add to Calendar"
              onClick={(ev) => {
                setModalOpen(true);
                ev.stopPropagation();
              }}
              role={ButtonRole.Ghost}
              size="large"
            />            
            <ConfirmAboutYou1 aboutYou1Data={null} getAddressValues={getAddressValues} isSubmit={isSubmiting}/>            
            <div className={styles.insCheck}>
              <input
                type="checkbox"
                checked={insChecked}
                onChange={handleChangeIns}
              />
              <label>
                {" "}
                I WILL NOT BE USING INSURANCE
              </label>
            </div>
            {!insChecked && (<ConfirmInsurance defaultValues={null} isSubmit={isSubmiting} isFormValidIns={isFormValidIns} getInsuranceValues={getInsuranceValues} currentPatient={patient}/>) }
            <CheckInCardNew appointment={selectedAppointment} onClick={onSubmitNew} isSubmit={isSubmiting}/>
          </div> 
        </div>
      </div>
      <CalendarLinkModal
        onRequestClose={() => setModalOpen(false)}
        isModalOpen={isModalOpen}
        links={calendarLinks}
      />
    </Layout>
  );
};

export const AppointmentSuccessNew: React.FC = () => (
  <WithOffice>
    {({ office }) => (
      <NeedsSelectedAppointment>
        {({ selectedAppointment, doctor }) => (
          <UnconnectedAppointmentSuccessNew
            office={office}
            doctor={doctor}
            selectedAppointment={selectedAppointment}
          />
        )}
      </NeedsSelectedAppointment>
    )}
  </WithOffice>
);
