import React from "react";
import cx from "classnames";
import styles from "./AppointmentSelectReturnStatus.module.scss";
import { PatientReturnStatus } from "types/patientReturnStatus";
import { Doctor } from "types/doctor";
import { Office } from "types/office";

interface AppointmentSelectReturnStatusProps {
  patientType: PatientReturnStatus | undefined;
  doctor: Doctor | undefined;
  office: Office;
  onSelection: (type: string, value: PatientReturnStatus) => void;
  onRestrictVEHSelection: () => void;
}

export const AppointmentSelectReturnStatus: React.FC<
  AppointmentSelectReturnStatusProps
> = ({ patientType, doctor, office, onSelection, onRestrictVEHSelection }) => {
  const showNew =
    doctor && doctor.offersVEH && office.restrictVEH ? false : true;
  return (
    <div className={styles.container}>
      <div className={styles.layout}>
        <div className={styles.prompt}>
          <h1 className={styles.headline}>
            Have you previously visited a MyEyeDr. location before?
          </h1>
          <hr className={styles.divider} />
          {!patientType && (
            <p className={styles.requiredMessage}>* Please select one.</p>
          )}
        </div>

        <div className={styles.choices}>
          <button
            className={
              patientType === PatientReturnStatus.Returning
                ? cx(styles.button, styles.selected)
                : styles.button
            }
            onClick={() =>
              onSelection("patientType", PatientReturnStatus.Returning)
            }
          >
            Yes
          </button>

          <button
            className={
              patientType === PatientReturnStatus.New
                ? cx(styles.button, styles.selected)
                : styles.button
            }
            onClick={() => {
              if (showNew) onSelection("patientType", PatientReturnStatus.New);
              else onRestrictVEHSelection();
            }}
          >
            No
          </button>
          <button
            className={
              patientType === PatientReturnStatus.Unknown
                ? cx(styles.button, styles.selected)
                : styles.button
            }
            onClick={() => {
              if (showNew)
                onSelection("patientType", PatientReturnStatus.Unknown);
              else onRestrictVEHSelection();
            }}
          >
            Not Sure
          </button>
        </div>
      </div>
    </div>
  );
};
