import React from "react";
import { WearOption } from "./WearOption";
import styles from "./AppointmentExamDetails.module.scss";
import glassesIcon from "images/no-circle-glasses-icon.svg";
import contactsIcon from "images/no-circle-contacts-icon.svg";
import glassesContactsIcon from "images/no-circle-glasses-contacts-icon.svg";
import eyeIcon from "images/no-circle-eye-icon.svg";
import { Button } from "components/Button";
import { ExamType } from "types/examType";
import { Modal } from "components/Modal";
import { Office } from "types/office";

const aria = {
  labelledby: "appointment-exam-details-modal-label",
  describedby: "appointment-exam-details-modal-description",
};

interface AppointmentExamDetailsProps {
  office: Office;
  eyeExam: ExamType | undefined;
  isMobileView?: boolean;
  onSelection: (type: string, value: ExamType) => void;
}

export const AppointmentExamDetails: React.FC<AppointmentExamDetailsProps> = ({
  eyeExam,
  isMobileView,
  onSelection,
}) => {
  const [patientOnlyWearsGlasses, setPatientOnlyWearsGlasses] =
    React.useState<boolean>(false);

  const headerText = isMobileView
    ? "Tell us about yourself"
    : "Tell us about yourself!";
  return (
    <>
      <div>
        <Modal
          aria={aria}
          isOpen={patientOnlyWearsGlasses}
          onRequestClose={() => setPatientOnlyWearsGlasses(false)}
        >
          <p id={aria.labelledby} className={styles.interrogative}>
            Are you interested in a contacts fitting?
          </p>
          <p id={aria.describedby} className={styles.qualifier}>
            Your comfort is important to us. We set aside extra time for
            first-time wearers.
          </p>
          <div className={styles.buttons}>
            <Button
              text="Yes"
              size="large"
              onClick={() => {
                setPatientOnlyWearsGlasses(false);
                onSelection("examType", ExamType.GlassesTryContacts);
              }}
              className={styles.button}
            />
            <Button
              text="No"
              size="large"
              onClick={() => {
                setPatientOnlyWearsGlasses(false);
                onSelection("examType", ExamType.GlassesNoTryContacts);
              }}
              className={styles.button}
            />
          </div>
        </Modal>

        <div className={styles.layout}>
          <div>
            <h1 className={styles.headline}>{headerText}</h1>
            <hr className={styles.divider} />
            <div className={styles.yourAnswer}>
              We&apos;d like to know what you currently use. Your answers will
              help us prepare for your visit.
            </div>
            {!eyeExam && (
              <p className={styles.requiredMessage}>* Please select one.</p>
            )}
          </div>

          <div className={styles.iWearGrid}>
            <WearOption
              label="I only wear glasses"
              value={eyeExam}
              examType={ExamType.GlassesTryContacts}
              isMobileView={isMobileView}
              onClick={() => {
                setPatientOnlyWearsGlasses(true);
              }}
              icon={glassesIcon}
            />
            <WearOption
              label="I only wear contacts"
              value={eyeExam}
              examType={ExamType.Contacts}
              isMobileView={isMobileView}
              onClick={() => onSelection("examType", ExamType.Contacts)}
              icon={contactsIcon}
            />
            <WearOption
              label="I wear glasses and contacts"
              value={eyeExam}
              examType={ExamType.GlassesAndContacts}
              isMobileView={isMobileView}
              onClick={() =>
                onSelection("examType", ExamType.GlassesAndContacts)
              }
              icon={glassesContactsIcon}
            />
            <WearOption
              label="I do not wear glasses or contacts"
              value={eyeExam}
              examType={ExamType.NoGlassesOrContacts}
              isMobileView={isMobileView}
              onClick={() =>
                onSelection("examType", ExamType.NoGlassesOrContacts)
              }
              icon={eyeIcon}
            />
          </div>
        </div>
      </div>
    </>
  );
};
