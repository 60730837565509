import React from "react";
import dayjs from "dayjs";
import styles from "./Footer.module.scss";

// Declare OneTrust as a global variable to prevent TypeScript errors
declare global {
  interface Window {
    OneTrust?: {
      ToggleInfoDisplay: () => void;
    };
  }
}

export const FooterPortal: React.FC = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.leftContainer}>
        <div className={styles.copyRight}>
          &#169; {dayjs().year()} MyEyeDr. All rights reserved.
        </div>
      </div>
      <div className={styles.rightContainer}>
        <ul>
          <li>
            <a
              href="https://www.myeyedr.com/privacy-policy-practices"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy & Practices
            </a>
          </li>
          <li>
            <a
              href="https://www.myeyedr.com/terms-use"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Use
            </a>
          </li>
          <li>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                if (window.OneTrust?.ToggleInfoDisplay) {
                  window.OneTrust.ToggleInfoDisplay();
                } else {
                  console.warn("OneTrust is not loaded yet.");
                }
              }}
            >
              Cookie Preferences
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};
