import { has } from "lodash";
import { Office } from "types/office";

export interface OfficeApiData {
  address1: string;
  address2: string;
  city: string;
  display_name: string;
  formatted_phone: string;
  formatted_zip_code: string;
  id: string;
  logical_id: string;
  scheduling_allowed: boolean;
  state_abbr: string;
  time_zone_name: string;
  distance_miles?: string;
  cobranded_logo_url?: string;
  supports_medicaid?: boolean;
  hours?: string;
  start_date?: string;
  start_time?: string;
  total_slots?: string;
  total_slots_details?: string;
  office_uid: string;
  time_slot_weeks: number;
  restrict_veh: boolean;
  veh_discount: boolean;
  veh_namechange: boolean;
  age_restrict_veh: boolean;
  offers_veh: boolean;
}

export const buildOffice = (data: OfficeApiData): Office => {
  return {
    address1: data.address1,
    address2: data.address2,
    city: data.city,
    displayName: data.display_name,
    formattedPhone: data.formatted_phone,
    formattedZipCode: data.formatted_zip_code,
    id: data.id,
    logicalId: data.logical_id,
    officeUid: data.office_uid,
    schedulingAllowed: data.scheduling_allowed,
    stateAbbr: data.state_abbr,
    timeZoneName: data.time_zone_name,
    timeSlotWeeks: data.time_slot_weeks,
    restrictVEH: data.restrict_veh,
    discountVEH: data.veh_discount,
    offersVEH: data.offers_veh,
    ageRestrictVEH: data.age_restrict_veh,
    namechangeVEH: data.veh_namechange,
    ...(has(data, "distance_miles") && {
      distanceMiles: Number(data.distance_miles).toFixed(1),
    }),
    ...(has(data, "cobranded_logo_url") && {
      cobrandedLogoUrl: data.cobranded_logo_url,
    }),
    ...(has(data, "supports_medicaid") && {
      supportsMedicaid: data.supports_medicaid,
    }),
    ...(has(data, "hours") && {
      hours: data.hours,
    }),
    ...(has(data, "total_slots") && {
      totalSlots: data.total_slots,
    }),
    ...(has(data, "start_date") && {
      startDate: data.start_date,
    }),
    ...(has(data, "start_time") && {
      startTime: data.start_time,
    }),
    ...(has(data, "total_slots_details") && {
      totalSlotsDetails: data.total_slots_details,
    }),
  };
};
