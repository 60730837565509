import { isEmpty } from "lodash";
import * as React from "react";
import { useSelector } from "react-redux";
// eslint-disable-next-line no-restricted-imports
import { Banner } from "../scheduling/components/Banner/Banner";
import styles from "./Layout.module.scss";
import { Navbar } from "components/Navbar";
import { FooterPortal } from "components/Footer/Footer";
import { selectSessionOffice } from "features/patientDashboard/patientDashboardSlice";

type LayoutProps = {
  children?: React.ReactNode;
  navbar?: React.ReactNode;
  emptyHeight?: boolean;
  calledFrom?: string;
  office?: any;
};

export const Layout: React.FC<LayoutProps> = ({
  children,
  navbar,
  emptyHeight = false,
  calledFrom,
}) => {
  const sessionOffice = useSelector(selectSessionOffice);
  return (
    <div className={styles.layout}>
      {navbar ? (
        navbar
      ) : !isEmpty(sessionOffice) ? (
        <Navbar office={sessionOffice} />
      ) : (
        <Navbar />
      )}

      <div className={styles.content}>{children}</div>
      <FooterPortal />
      {emptyHeight && (
        <div
          className={
            calledFrom && calledFrom === "perscription"
              ? styles.emptyHeight2
              : calledFrom && calledFrom === "summaryPage"
              ? styles.summaryHeight
              : styles.emptyHeight
          }
        ></div>
      )}
    </div>
  );
};
export const LayoutV2: React.FC<LayoutProps> = ({
  children,
  navbar,
  office,
}) => {
  return (
    <div className={styles.layoutV2}>
      <Banner />
      {navbar ? navbar : office ? <Navbar office={office} /> : <Navbar />}

      <div className={styles.contentV2}>{children}</div>
    </div>
  );
};
