import React from "react";
//import { useHistory } from "react-router-dom";
import cx from "classnames";
import { isEmpty } from "lodash";
// eslint-disable-next-line no-restricted-imports
import { toTel } from "../utils/toTel";
import styles from "./OfficeCard.module.scss";
import { Office } from "types/office";
import { Slot } from "types/slot";
import phoneIcon from "images/callIcon.svg";
import handbagIcon from "images/medical-handbag-blue.svg";
import { externalRedirect } from "utils/externalRedirect";
import * as paths from "features/routing/paths";
import { getOfficeHours } from "utils/getOfficeHours";

interface OfficeCardProps {
  office: Office;
  selectedSlot: undefined | Slot;
  isEditForm: false;
}
declare let REACT_APP_ENV: string | undefined;
export const OfficeCard: React.FC<OfficeCardProps> = ({
  office,
  selectedSlot,
  isEditForm,
}) => {
  const refStr = "#";
  //const history = useHistory();
  const onClick = () => {
    const appEnv = typeof REACT_APP_ENV !== "undefined" ? REACT_APP_ENV : "";
    if (appEnv === "undefined") {
      externalRedirect(paths.LOCATIONS_PAGE_SEARCH_URL);
    } else {
      externalRedirect(paths.LOCATIONS_PAGE_SEARCH_URL);
      // window.location.href = window.location.origin + paths.offices();
    }
    return false;
  };

  return (
    <>
      <div
        className={
          isEmpty(selectedSlot) || isEditForm
            ? styles.container
            : cx(styles.container, styles.moveTop)
        }
      >
        <div className={styles.headerSection}>
          <div className={styles.heading}>Selected Office</div>
          <div className={styles.action}>
            <a href={refStr} onClick={onClick}>
              Change
            </a>
          </div>
        </div>
        <div className={styles.contentSection}>
          <div className={styles.contentLeft}>
            <div className={styles.officeName}>{office.displayName}</div>
            <div className={styles.officeAddress}>
              {office.address1} <br />
              {office.city}, {office.stateAbbr}&nbsp;{office.formattedZipCode}
            </div>
          </div>
          <div className={styles.contentRight}>
            <div className={styles.timings}>{getOfficeHours(office)}</div>
            <div className={styles.officePhone}>
              <a
                href={toTel(office.formattedPhone)}
                className={styles.footerPhoneLink}
              >
                <img
                  src={phoneIcon}
                  alt="Contact Phone"
                  className={styles.icon}
                />
                {office.formattedPhone}
              </a>
            </div>
            {office.supportsMedicaid && (
              <div className={styles.medicaid}>
                <img src={handbagIcon} alt="" className={styles.icon} />
                Medicaid <span className={styles.boldUnderline}>is</span>{" "}
                accepted{" "}
              </div>
            )}
            {!office.supportsMedicaid && (
              <div className={styles.medicaid}>
                <img src={handbagIcon} alt="" className={styles.icon} />
                Medicaid <span className={styles.boldUnderline}>
                  is not
                </span>{" "}
                accepted{" "}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
